import React from "react";
import classes from "./introduction.module.scss";

export default function Introduction() {
  return (
    <section className={classes.introduction} aria-label="Introduction">
      <h1 className={classes.heading}>Hi, I'm Nishant Naithani!</h1>
      <div className={classes.content}>
        <p className={classes.otherData}>
          A Senior Software Development Engineer specializing in crafting dynamic
          Web Applications using cutting-edge technologies such as ReactJS and
          Node.js. Currently, I thrive in my role at{" "}
          <a
            href="https://www.peoplegrove.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.peopleGrove}
            aria-label="Visit PeopleGrove website"
          >
            PeopleGrove
          </a>
          .
        </p>
        <p className={classes.otherData}>
          I'm passionate about building robust web solutions that enhance user
          experiences and drive business growth.
        </p>
        <p className={classes.otherData}>
          Explore my journey in detail by downloading my cv{" "}
          <a
            href={process.env.PUBLIC_URL + "/docs/Resume.pdf"}
            download="Nishant_Naithani_CV"
            target="_blank"
            rel="noreferrer"
            className={classes.resume}
            aria-label="Download Nishant Naithani's CV"
          >
            here
          </a>{" "}
          or connect with me on{" "}
          <a
            href="https://www.linkedin.com/in/nishant-naithani-070197/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.linkedIN}
            aria-label="Connect with Nishant Naithani on LinkedIn"
          >
            LinkedIn
          </a>{" "}
          to stay updated on my professional insights.
        </p>
      </div>
    </section>
  );
}
