import React, { useState } from "react";
import classes from "./selfImage.module.scss";

export default function SelfImage() {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <figure className={classes.imageContainer}>
      {isLoading && (
        <div 
          className={classes.imagePlaceholder} 
          role="status"
          aria-label="Loading profile image"
        />
      )}
      <img
        className={`${classes.selfImage} ${isLoading ? classes.hidden : ''}`}
        src='https://res.cloudinary.com/dfhtkgibn/image/upload/v1715270613/portfolio/SelfImageLarge_ly410l.webp'
        alt="Nishant Naithani - Senior Software Engineer"
        loading="lazy"
        width="500"
        height="500"
        onLoad={() => setIsLoading(false)}
        onError={(e) => {
          console.error('Error loading image:', e);
          setIsLoading(false);
        }}
      />
      <figcaption className={classes.imageCaption}>
        Nishant Naithani - Senior Software Engineer
      </figcaption>
    </figure>
  );
}
